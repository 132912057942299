<template>
    <TemplateBase
        v-if="entry"
        :key="entry.id"
        :entry="entry"
        class="template template--content-page"
        :data-has-access="userHasAccess"
    >
        <template #hero>
            <slot name="hero">
                <HeroDetail :entry="entry" />
            </slot>
        </template>

        <template
            v-if="userHasAccess"
            #default
        >
            <slot name="default">
                <IndexSection v-if="showIndex" />

                <ContentBlocks
                    :content-blocks="entry.contentBlocks"
                />
            </slot>

            <BaseSection
                v-if="!$slots.aside && (entry.departments?.length > 0 || entry.experts?.length > 0)"
                title="Meer weten?"
                class="section--title-medium"
                section-width="content"
            >
                <SupportOverview
                    :departments="entry.departments"
                    :experts="entry.experts"
                    class="support-overview--content"
                />
            </BaseSection>

            <BaseSection
                v-if="showShareButtons"
                section-width="content"
            >
                <SocialShare />
            </BaseSection>
        </template>

        <template #related>
            <slot
                v-if="userHasAccess"
                name="related"
            >
                <slot name="related-pre" />

                <BaseSection
                    v-if="entry.dossiers && entry.dossiers.length"
                    title="Onderdeel van dossier"
                    class="section--title-small"
                    section-width="content"
                >
                    <CardList>
                        <DossierCard
                            v-for="dossier in entry.dossiers"
                            :key="dossier.id"
                            :entry="dossier"
                            :is-inline="true"
                        />
                    </CardList>
                </BaseSection>
            </slot>

            <ContentWall
                v-else
                :international-only="isInternationalOnly(entry)"
                :fenex-only="isFenexOnly(entry)"
            />
        </template>

        <template
            v-if="!!$slots.aside"
            #aside
        >
            <slot name="aside" />
        </template>
    </TemplateBase>
</template>

<script setup>
import { useAuthStore } from '~/store/auth';
import { hasAccess, isInternationalOnly, isFenexOnly } from '~/utils/access';

const props = defineProps({
    entry: {
        type: Object,
        default: () => {},
        required: true
    },
    showShareButtons: {
        type: Boolean,
        default: true
    }
});

const userHasAccess = computed(() => {
    const user = useAuthStore().user;

    return hasAccess(props.entry, user?.content_permissions);
});

const showIndex = computed(() => {
    return props.entry?.showIndex ?? false;
});
</script>
